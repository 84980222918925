.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  scroll-behavior: smooth;
}
.home-modal {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  z-index: 200;
  position: fixed;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  backdrop-filter: blur(4px);
  justify-content: center;
}
.home-practices {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  box-shadow: 0px 0px 25px -2px rgba(66, 68, 90, 0.4);
  align-items: center;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
.home-heading {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.home-header {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.home-close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  transition: 0.3s;
}
.home-close:hover {
  opacity: 0.5;
}
.home-grid {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: grid;
  align-items: flex-start;
  flex-direction: column;
  grid-template-columns: repeat(2, 1fr);
}
.home-section {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading01 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header01 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption {
  max-width: 385px;
  line-height: 24px;
}
.home-text {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image {
  width: 12px;
  object-fit: cover;
}
.home-section01 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading02 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header02 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption1 {
  max-width: 385px;
  line-height: 24px;
}
.home-text01 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image01 {
  width: 12px;
  object-fit: cover;
}
.home-section02 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading03 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header03 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption2 {
  max-width: 385px;
  line-height: 24px;
}
.home-text02 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image02 {
  width: 12px;
  object-fit: cover;
}
.home-section03 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header04 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption3 {
  max-width: 385px;
  line-height: 24px;
}
.home-text03 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image03 {
  width: 12px;
  object-fit: cover;
}
.home-section04 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading05 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header05 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption4 {
  max-width: 385px;
  line-height: 24px;
}
.home-text04 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image04 {
  width: 12px;
  object-fit: cover;
}
.home-section05 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header06 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption5 {
  max-width: 385px;
  line-height: 24px;
}
.home-text05 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image05 {
  width: 12px;
  object-fit: cover;
}
.home-hero {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-navbar {
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.home-left {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-image06 {
  width: 130px;
  object-fit: cover;
}
.home-links {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-link {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.home-link:hover {
  opacity: 0.5;
}
.home-link1 {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.home-link1:hover {
  opacity: 0.5;
}
.home-link2 {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.home-link2:hover {
  opacity: 0.5;
}
.home-link3 {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.home-link3:hover {
  opacity: 0.5;
}
.home-right {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-book {
  text-decoration: none;
  background-color: #081fda;
}
.home-text06 {
  font-family: Inter;
}
.home-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.home-icon1 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 1000;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-image07 {
  height: 2rem;
}
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon3 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text07 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text08 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text09 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text10 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-book1 {
  text-decoration: none;
}
.home-image08 {
  width: 18px;
  object-fit: cover;
}
.home-text11 {
  font-family: Inter;
}
.home-main {
  width: 100%;
  min-height: 70vh;
  display: flex;
  z-index: 100;
  align-items: stretch;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: 11px;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--dl-color-primary-081fda);
  color: #fff;
  padding-top: 10vh;
  padding-bottom: 10vh;
}
.home-content {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
}
.home-heading07 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
}
.home-text12 {
  color: var(--dl-color-primary-500);
  font-weight: 700;
}
.home-header07 {
  color: var(--dl-color-primary-700);
  width: 779px;
  font-size: 62px;
  max-width: 750px;
  font-style: normal;
  font-weight: 600;
  line-height: 74px;
}
.home-text13 {
  font-size: 59px;
  max-width: 60%;
}
.home-text13 span {
    color: var(--dl-color-primary-500);
}
.home-text14 {
  font-size: 59px;
}
.home-caption6 {
  color: var(--dl-color-primary-700);
  width: 720px;
  height: 76px;
  font-size: 18px;
  max-width: var(--dl-size-size-maxwidth);
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}
.home-features {
  gap: 120px;
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-section06 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.home-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header08 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading08 {
  color: var(--dl-color-primary-3a02ba);
  font-size: 4vw;
  max-width: 600px;
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
}

.home-header08 p, .home-header08 ul {
    margin-left: var(--dl-space-space-twounits);
}
.home-header08 p, .home-header08 li {
    list-style-position: inside;
}
.home-text20 {
  color: var(--dl-color-primary-300);
}
.home-text21 {
  color: var(--dl-color-primary-300);
}
.home-capton {
  font-size: 18px;
  max-width: 600px;
  line-height: 27px;
  margin-left: var(--dl-space-space-twounits);
}
.home-image09 {
  width: 580px;
  height: 580px;
  object-fit: cover;
}
.home-section07 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header09 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading09 {
  color: var(--dl-color-primary-3a02ba);
  font-size: 4vw;
  max-width: 600px;
  font-style: normal;
  font-weight: 600;
  margin-right: var(--dl-space-space-twounits);
}
.home-text25 {
  color: var(--dl-color-primary-300);
}
.home-capton1 {
  font-size: 18px;
  max-width: 600px;
  line-height: 27px;
  margin-right: var(--dl-space-space-twounits);
}
.home-image10 {
  width: 580px;
  height: 580px;
  object-fit: cover;
}
.home-features1 {
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  background-color: var(--dl-color-primary-3a02ba);
}
.home-content3 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.home-section08 {
  position: relative;
}
.home-heading10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-header10 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.home-text27 {
  color: rgb(255, 255, 255);
  width: 100%;
  max-width: 300px;
  line-height: 24px;
}
.home-divider {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: none;
}
.home-section09 {
  position: relative;
}
.home-heading11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-header11 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.home-text28 {
  color: rgb(255, 255, 255);
  width: 100%;
  max-width: 300px;
  line-height: 24px;
}
.home-divider1 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: none;
}
.home-section10 {
  position: relative;
}
.home-heading12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-header12 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.home-text29 {
  color: rgb(255, 255, 255);
  width: 100%;
  max-width: 300px;
  line-height: 24px;
}
.home-practices1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: 120px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: var(--dl-color-primary-700);
}
.home-heading13 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text30 {
  color: #000;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
}
.home-text30 span {
  color: var(--dl-color-primary-081fda);
}
.home-text31 {
  color: #000;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
    margin: 3rem 3rem 0;
  text-align: center;
}
.discover {
    margin: 3rem 0 0;
}
.home-content4 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-grid1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: grid;
  align-items: flex-start;
  flex-direction: column;
  grid-template-columns: repeat(2, 1fr);
}
.home-practice-wrapper {
  text-decoration: none;
}
.home-book3 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-top: 120px;
  border-color: #B4B9C6;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: var(--dl-color-primary-700);
  border-bottom-width: 1px;
}
.home-heading14 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text32 {
  color: var(--dl-color-primary-3a02ba);
  font-size: 56px;
  max-width: 600px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.home-text37 {
  font-size: 18px;
  max-width: 700px;
  text-align: center;
  line-height: 27px;
}
.home-form {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-types {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-inputs {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-role {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: center;
}
.home-textinput3 {
  margin-right: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
}
.home-select {
  width: 100%;
  height: 57px;
  padding: var(--dl-space-space-halfunit);
  border-color: #ffffff;
  border-width: 10px;
}
.home-container02 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container03 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-text42 {
  font-size: 14px;
  line-height: 1;
  margin-left: var(--dl-space-space-unit);
}
.home-lower {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-button {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-book4 {
  width: 100%;
  background-color: var(--dl-color-primary-3a02ba);
}
.home-text44 {
  font-size: 10px;
  max-width: 700px;
  text-align: left;
  line-height: 15px;
}
.home-text46 {
  color: #2461ff;
  font-style: normal;
  font-weight: 600;
}
.home-text48 {
  color: #2461ff;
  font-style: normal;
  font-weight: 600;
}
.home-container04 {
  width: 100%;
  height: 142px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-container05 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container06 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-text50 {
  line-height: 1.5;
  padding-right: var(--dl-space-space-oneandhalfunits);
}
.home-text52 {
  font-weight: 700;
}
.home-icon5 {
  width: 24px;
  height: 24px;
}
.home-download {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-footer {
  gap: 215px;
  width: 100%;
  height: 78px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #000000;
}
.home-left1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  width: 1025px;
  height: var(--dl-size-size-xsmall);
  display: flex;
  align-items: flex-start;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-legal {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-copyright {
  color: rgb(255, 255, 255);
  font-size: 12px;
}
.home-legal1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.home-row {
  display: flex;
}
.home-copyright5 {
  color: rgb(255, 255, 255);
  font-size: 12px;
}
.home-container08 {
  display: contents;
}
.home-container10 {
  display: contents;
}
.home-container12 {
  display: contents;
}
@media(max-width: 991px) {
  .home-hero {
    flex: 1;
    height: 0px;
  }
  .home-navbar {
    z-index: 1000;
  }
  .home-links {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    padding: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-round);
    background-color: #2461FF;
  }
  .home-icon1 {
    fill: white;
  }
  .home-main {
    flex-direction: column;
    height: auto;
    padding: 20vh 10vh;
  }
  .home-content {
    padding-top: 0px;
    padding-bottom: 0px;
      max-width: 
  }
  .home-header07 {
    max-width: 75%;
  }
  .home-text13, .home-caption6 {
    max-width: 100%;
  }
  .home-features {
    gap: var(--dl-space-space-threeunits);
    height: 100%;
  }
  .home-section06 {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-image09 {
    width: 100%;
  }
  .home-section07 {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
    .discover {
        text-align: center;
        margin: 3rem;
    }
  .home-heading08, .home-header08 p, .home-header08 ul {
    max-width: 100%;
    margin-left: 0;
  }
  .home-capton{
    margin-left: 0;
  }
  .home-image10 {
    width: 100%;
  }
  .home-features1 {
    padding-top: var(--dl-space-space-fourunits);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content3 {
    flex-direction: column;
  }
  .home-section08 {
    border-right-width: 0px;
  }
  .home-divider {
    width: 80px;
    height: 1px;
    display: flex;
    border-color: #ffffff;
    border-width: 0px;
    background-color: #ffffff;
  }
  .home-section09 {
    border-right-width: 0px;
  }
  .home-divider1 {
    width: 80px;
    height: 1px;
    display: flex;
    border-color: #ffffff;
    border-width: 0px;
    background-color: #ffffff;
  }
  .home-section10 {
    border-right-width: 0px;
  }
  .home-practices1 {
    flex-direction: column;
  }
  .home-footer {
    gap: 100px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-left1 {
    padding-right: 0px;
  }
  .home-legal1 {
    gap: var(--dl-space-space-twounits);
    display: flex;
    flex-direction: column;
  }
  .home-row {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 767px) {
  .home-navbar {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-burger-menu {
    display: flex;
  }
  .home-main {
    gap: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    justify-content: center;
  }
  .home-header07 {
    font-size: 32px;
    max-width: 70%;
    line-height: 38px;
  }
  .home-text13 {
    font-size: 10vw;
    line-height: 12vw;
  }
  .home-caption6 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-features {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading08 {
    font-size: 30px;
    line-height: 36px;
  }
  .home-capton {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .home-heading09 {
    font-size: 30px;
    line-height: 36px;
  }
  .home-capton1 {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .home-content3 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-header10 {
    font-size: 20px;
    line-height: 24px;
  }
  .home-text27 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-header11 {
    font-size: 20px;
    line-height: 24px;
  }
  .home-text28 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-header12 {
    font-size: 20px;
    line-height: 24px;
  }
  .home-text29 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-practices1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading13 {
    gap: var(--dl-space-space-unit);
  }
  .home-text30 {
    font-size: 30px;
    line-height: 36px;
  }
  .home-text31 {
    font-size: 24px;
    line-height: 21px;
  }
  .home-content4 {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
  }
  .home-grid1 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
  .home-book3 {
    gap: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading14 {
    gap: var(--dl-space-space-unit);
  }
  .home-text32 {
    font-size: 24px;
  }
  .home-text37 {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .home-text38 {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .home-form {
    gap: var(--dl-space-space-threeunits);
  }
  .home-types {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-inputs {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-left1 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .home-navbar {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-header07 {
    max-width: 80%;
  }
  .home-text13, .home-caption6 {
    max-width: 100%;
  }
  .home-book2 {
    width: 100%;
  }
  .home-types {
    flex-direction: column;
  }
  .home-image10 {
    display: none;
  }
}
