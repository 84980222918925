/*App Specific*/

// Variables
$primary: #3A02BA;
$mix-1: #663CCB;
$mix-2: #9175DD;
$mix-3: #BDAFEE;
$secondary: #E8E8FF;
$text-color-primary: #000;
$text-color-secondary: #737373;
$bg-primary: #fff;
$bg-secondary: #f3f3f3;
$key: 1rem;
$half-key: 0.5rem;
$thirds-key: 0.75rem;



.grid {
    display: grid;
    width: 100%;
}

.cols-2 {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: calc($key*2);
    grid-row-gap: calc($key*2);
}

.cols-3 {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: calc($key*2);
    grid-row-gap: calc($key*2);
}

.cols-4 {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: calc($key*2);
    grid-row-gap: calc($key*2);
}

.cols-5 {
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: calc($key*2);
    grid-row-gap: calc($key*2);
}

.cols-6 {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: calc($key*2);
    grid-row-gap: calc($key*2);
}

.cols-7 {
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: calc($key*2);
    grid-row-gap: calc($key*2);
}


.cols-8 {
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: calc($key*2);
    grid-row-gap: calc($key*2);
}

.cols-9 {
    grid-template-columns: repeat(9, 1fr);
    grid-column-gap: calc($key*2);
    grid-row-gap: calc($key*2);
}

.cols-10 {
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: calc($key*2);
    grid-row-gap: calc($key*2);
}

.cols-11 {
    grid-template-columns: repeat(11, 1fr);
    grid-column-gap: calc($key*2);
    grid-row-gap: calc($key*2);
}

.cols-12 {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: calc($key*2);
    grid-row-gap: calc($key*2);
}

@media (max-width: 769px) {
    .cols-2, .cols-3 {
        grid-template-columns: 1fr;
    }
    .cols-4 {
        grid-template-columns: 1fr 1fr;
    }
}


.padb {
    padding-bottom: $key;
}

.padt {
    padding-top: $key;
}

.brand-portal {
    grid-template-rows: repeat(2, 1fr);
    height: calc(100vh - ($key*2));
    padding: $key;

    .intro {
        grid-area: unset;
        display: flex;
    }

    .jumpto {
        background: $secondary;
        padding: $key;
        grid-area: 2 / 1 / 2 / 5;
        position: relative;

        span {
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: $thirds-key;
            display: block;
            padding: $half-key;
        }

        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                list-style: none;

                a {
                    display: inline-block;
                    background: $primary;
                    line-height: $key;
                    padding: $half-key $key;
                    margin: 5px;
                    color: $bg-primary;
                    border-radius: $key;

                    &:hover {
                        background: $mix-1;
                    }
                }
            }
        }
    }

    .content {
        grid-column-start: 1;
        grid-column-end: 4;

        .component {
            margin: 0 0 $key;
            padding: $key;
            box-shadow: 0px 0px 10px $secondary;
            border: 1px solid $secondary;
            border-radius: $half-key;

            .division {
                display: flex;

                > h3 {
                    margin-right: $key;
                    min-width: 15vw;
                }
            }

            > h3,
            > h2 {
                margin-bottom: $key;
                font-size: $key;
                font-weight: 400;
            }

            > h2 {
                color: $primary;
                padding-bottom: $key;
                border-bottom: 1px solid $secondary;
            }
        }

        ul {
            margin-bottom: $key;

            li {
                list-style-position: inside;
                padding: $half-key 0;

                ul {
                    margin-top: $half-key;
                    padding-left: $key;
                }
            }
        }
    }

    .sidebar {
        min-height: 50vh;
        padding: $key;
        background-color: $bg-secondary;
    }
}

.FlowTalk {
    height: 100vh;
    padding: $key;
    grid-template-columns: 300px 1fr 1fr;
    grid-template-rows: 70px 1fr 100px;
    
    @media (max-width: 769px) {
        grid-template-columns: 1fr;
    }


    .intro {
        grid-area: 1 / 1 / 2 / 4;
        display: flex;
        
         @media (max-width: 769px) {
            grid-area: unset;
             display: block;
        }

        h1 {
            text-align: left;
            color: $primary;
            padding: $half-key 0;
        }

        p {
            padding: $half-key 0 0 0;
        }

        .upload-box {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            
            @media (max-width: 769px) {
                text-align: center;
                margin-top: $key;
            }

            #upload {
                color: #737373;
                font-size: 1rem;
                font-style: italic;
                position: relative;
                font-size: 0.75rem;
            }

            input[type=file] {
                margin-top: 4px;
                background: $secondary;
                padding: 1.25rem;
                font-size: 0.75rem;
                border-radius: $key;
                margin-left: 1rem;
                
                 @media (max-width: 769px) {
                     width: 100%;
                     margin-left: 0;
                }
            }

            input[type=file]::file-selector-button {
                font-weight: bold;
                background: $primary;
                color: #fff;
                padding: 0.5em;
                border: none;
                border-radius: 3px;
                cursor: pointer;
            }

        }
    }


    .content {
        grid-area: 2 / 2 / 3 / 4;
        min-height: 50vh;
        
        @media (max-width: 769px) {
            grid-area: unset;
        }

        .convo {
            max-height: 65vh;
            overflow: scroll;
            div {
                padding: $key;
                border-radius: $key;
                margin: $key;
            }

            div:nth-child(even) {
                background: $secondary;
            }

            div:nth-child(odd) {
                background: $mix-3;
            }
            
            #scroll-dummy {
                background: transparent!important;
                padding: 0!important;
                margin: 0!important;
            }
        }

        .loading {
            color: $text-color-secondary;
            font-size: $thirds-key;
            font-style: italic;
            padding: 0 $key;
        }
    }

    .pdf-content {
        p {
            font-size: 1rem;
            letter-spacing: 3px;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }
    }

    .sidebar {
        grid-area: 2 / 1 / 4 / 2;
        padding: $key;
        background-color: $bg-secondary;
        overflow: scroll;
        
        @media (max-width: 769px) {
            grid-area: 4;
        }
        
        &.brand-summary {
            div {
                div {
                height: 50px;
                background: #333;
                }
            }
            #colors {
                display: flex;
                justify-content: space-between;
                padding: $key 0;
                li {
                    height: 50px;
                    width: 50%;
                    border: 1px solid #ddd;
                    flex-wrap: wrap;
                    list-style: none;
                    margin: 0 5px calc($key*2);
                    position: relative;
                    
                    &:first-child {
                    margin-left: 0;
}
                    
                     &:last-child {
                    margin-right: 0;
}
                    
                    .color-name {
                      position: absolute;
                      bottom: -1.5rem;
                      width: 100%;
                      text-align: center;
                      font-size: $thirds-key;
                    }
                    
                    }
                }
            #main-img {
                width: 100%;
            }
            #images {
                grid-column-gap: calc($half-key*2);
    grid-row-gap: calc($half-key*2);
                img {
                    width: 100%;
                }
            }
            p {
                margin-bottom: $key;
            }
        }
    }
    
    @media (max-width: 769px) {
    .sidebar .cols-2 {
        grid-template-columns: 1fr 1fr;
    }
    .cols-4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}


    .magic {
        grid-area: 3/2/4/4;
        
        @media (max-width: 769px) {
            grid-area: unset;
        }

        form {
            display: flex;
            background-color: $secondary;
            border-radius: $key;
            width: 100%;
            padding: $half-key;
            margin: 0 0 $key 0;

            input {
                background-color: $secondary;
                width: 100%;
                padding: $half-key;
            }

            button[type=submit] {
                padding: $half-key;
                cursor: pointer;
                color: $primary;

                .material-symbols-outlined {
                    margin-top: 2px;
                }
            }
        }

        .tooltip {
            color: $text-color-secondary;
            font-size: $thirds-key;
            font-style: italic;
            padding: 0 $key;
            position: relative;
            margin-left: $key;


            .material-symbols-outlined {
                font-size: $key;
                position: absolute;
                left: 0;
                bottom: 0.75px;
            }
        }
    }

    .loader {
        height: 9px;
        width: 60px;
        --c: no-repeat linear-gradient(#3A02BA 0 0);
        background: var(--c), var(--c), var(--c), var(--c);
        background-size: 26% 3px;
        animation: l2 1s infinite;
    }

    @keyframes l2 {

        0%,
        70%,
        100% {
            background-position: calc(0*100%/3) 100%, calc(1*100%/3) 100%, calc(2*100%/3) 100%, calc(3*100%/3) 100%
        }

        14% {
            background-position: calc(0*100%/3) 0, calc(1*100%/3) 100%, calc(2*100%/3) 100%, calc(3*100%/3) 100%
        }

        28% {
            background-position: calc(0*100%/3) 100%, calc(1*100%/3) 0, calc(2*100%/3) 100%, calc(3*100%/3) 100%
        }

        42% {
            background-position: calc(0*100%/3) 100%, calc(1*100%/3) 100%, calc(2*100%/3) 0, calc(3*100%/3) 100%
        }

        56% {
            background-position: calc(0*100%/3) 100%, calc(1*100%/3) 100%, calc(2*100%/3) 100%, calc(3*100%/3) 0
        }
    }

    @keyframes l5 {
        100% {
            transform: rotate(1turn)
        }
    }
}

//CONTACT FORM

.form {
    width: 100vw;
    min-height: 1800px;
    margin: 50px 0;
}



///BRAND PORTAL

.brand-portal {
    .container {
        margin: 50px 0;
    }
    

    .color-containers {
        width: 100%;
        ul, ul li{
            display: block;
            margin: $half-key;
            border-radius: 50%;
        }
    }
}
