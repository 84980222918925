/*App Specific*/
.grid {
  display: grid;
  width: 100%;
}

.cols-2 {
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.cols-3 {
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.cols-4 {
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.cols-5 {
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.cols-6 {
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.cols-7 {
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.cols-8 {
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.cols-9 {
  grid-template-columns: repeat(9, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.cols-10 {
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.cols-11 {
  grid-template-columns: repeat(11, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.cols-12 {
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

@media (max-width: 769px) {
  .cols-2, .cols-3 {
    grid-template-columns: 1fr;
  }
  .cols-4 {
    grid-template-columns: 1fr 1fr;
  }
}
.padb {
  padding-bottom: 1rem;
}

.padt {
  padding-top: 1rem;
}

.brand-portal {
  grid-template-rows: repeat(2, 1fr);
  height: calc(100vh - 2rem);
  padding: 1rem;
}
.brand-portal .intro {
  grid-area: unset;
  display: flex;
}
.brand-portal .jumpto {
  background: #E8E8FF;
  padding: 1rem;
  grid-area: 2/1/2/5;
  position: relative;
}
.brand-portal .jumpto span {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.75rem;
  display: block;
  padding: 0.5rem;
}
.brand-portal .jumpto ul {
  display: flex;
  flex-wrap: wrap;
}
.brand-portal .jumpto ul li {
  list-style: none;
}
.brand-portal .jumpto ul li a {
  display: inline-block;
  background: #3A02BA;
  line-height: 1rem;
  padding: 0.5rem 1rem;
  margin: 5px;
  color: #fff;
  border-radius: 1rem;
}
.brand-portal .jumpto ul li a:hover {
  background: #663CCB;
}
.brand-portal .content {
  grid-column-start: 1;
  grid-column-end: 4;
}
.brand-portal .content .component {
  margin: 0 0 1rem;
  padding: 1rem;
  box-shadow: 0px 0px 10px #E8E8FF;
  border: 1px solid #E8E8FF;
  border-radius: 0.5rem;
}
.brand-portal .content .component .division {
  display: flex;
}
.brand-portal .content .component .division > h3 {
  margin-right: 1rem;
  min-width: 15vw;
}
.brand-portal .content .component > h3,
.brand-portal .content .component > h2 {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
}
.brand-portal .content .component > h2 {
  color: #3A02BA;
  padding-bottom: 1rem;
  border-bottom: 1px solid #E8E8FF;
}
.brand-portal .content ul {
  margin-bottom: 1rem;
}
.brand-portal .content ul li {
  list-style-position: inside;
  padding: 0.5rem 0;
}
.brand-portal .content ul li ul {
  margin-top: 0.5rem;
  padding-left: 1rem;
}
.brand-portal .sidebar {
  min-height: 50vh;
  padding: 1rem;
  background-color: #f3f3f3;
}

.FlowTalk {
  height: 100vh;
  padding: 1rem;
  grid-template-columns: 300px 1fr 1fr;
  grid-template-rows: 70px 1fr 100px;
}
@media (max-width: 769px) {
  .FlowTalk {
    grid-template-columns: 1fr;
  }
}
.FlowTalk .intro {
  grid-area: 1/1/2/4;
  display: flex;
}
@media (max-width: 769px) {
  .FlowTalk .intro {
    grid-area: unset;
    display: block;
  }
}
.FlowTalk .intro h1 {
  text-align: left;
  color: #3A02BA;
  padding: 0.5rem 0;
}
.FlowTalk .intro p {
  padding: 0.5rem 0 0 0;
}
.FlowTalk .intro .upload-box {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 769px) {
  .FlowTalk .intro .upload-box {
    text-align: center;
    margin-top: 1rem;
  }
}
.FlowTalk .intro .upload-box #upload {
  color: #737373;
  font-size: 1rem;
  font-style: italic;
  position: relative;
  font-size: 0.75rem;
}
.FlowTalk .intro .upload-box input[type=file] {
  margin-top: 4px;
  background: #E8E8FF;
  padding: 1.25rem;
  font-size: 0.75rem;
  border-radius: 1rem;
  margin-left: 1rem;
}
@media (max-width: 769px) {
  .FlowTalk .intro .upload-box input[type=file] {
    width: 100%;
    margin-left: 0;
  }
}
.FlowTalk .intro .upload-box input[type=file]::file-selector-button {
  font-weight: bold;
  background: #3A02BA;
  color: #fff;
  padding: 0.5em;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.FlowTalk .content {
  grid-area: 2/2/3/4;
  min-height: 50vh;
}
@media (max-width: 769px) {
  .FlowTalk .content {
    grid-area: unset;
  }
}
.FlowTalk .content .convo {
  max-height: 65vh;
  overflow: scroll;
}
.FlowTalk .content .convo div {
  padding: 1rem;
  border-radius: 1rem;
  margin: 1rem;
}
.FlowTalk .content .convo div:nth-child(even) {
  background: #E8E8FF;
}
.FlowTalk .content .convo div:nth-child(odd) {
  background: #BDAFEE;
}
.FlowTalk .content .convo #scroll-dummy {
  background: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
}
.FlowTalk .content .loading {
  color: #737373;
  font-size: 0.75rem;
  font-style: italic;
  padding: 0 1rem;
}
.FlowTalk .pdf-content p {
  font-size: 1rem;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.FlowTalk .sidebar {
  grid-area: 2/1/4/2;
  padding: 1rem;
  background-color: #f3f3f3;
  overflow: scroll;
}
@media (max-width: 769px) {
  .FlowTalk .sidebar {
    grid-area: 4;
  }
}
.FlowTalk .sidebar.brand-summary div div {
  height: 50px;
  background: #333;
}
.FlowTalk .sidebar.brand-summary #colors {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
}
.FlowTalk .sidebar.brand-summary #colors li {
  height: 50px;
  width: 50%;
  border: 1px solid #ddd;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 5px 2rem;
  position: relative;
}
.FlowTalk .sidebar.brand-summary #colors li:first-child {
  margin-left: 0;
}
.FlowTalk .sidebar.brand-summary #colors li:last-child {
  margin-right: 0;
}
.FlowTalk .sidebar.brand-summary #colors li .color-name {
  position: absolute;
  bottom: -1.5rem;
  width: 100%;
  text-align: center;
  font-size: 0.75rem;
}
.FlowTalk .sidebar.brand-summary #main-img {
  width: 100%;
}
.FlowTalk .sidebar.brand-summary #images {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}
.FlowTalk .sidebar.brand-summary #images img {
  width: 100%;
}
.FlowTalk .sidebar.brand-summary p {
  margin-bottom: 1rem;
}
@media (max-width: 769px) {
  .FlowTalk .sidebar .cols-2 {
    grid-template-columns: 1fr 1fr;
  }
  .FlowTalk .cols-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.FlowTalk .magic {
  grid-area: 3/2/4/4;
}
@media (max-width: 769px) {
  .FlowTalk .magic {
    grid-area: unset;
  }
}
.FlowTalk .magic form {
  display: flex;
  background-color: #E8E8FF;
  border-radius: 1rem;
  width: 100%;
  padding: 0.5rem;
  margin: 0 0 1rem 0;
}
.FlowTalk .magic form input {
  background-color: #E8E8FF;
  width: 100%;
  padding: 0.5rem;
}
.FlowTalk .magic form button[type=submit] {
  padding: 0.5rem;
  cursor: pointer;
  color: #3A02BA;
}
.FlowTalk .magic form button[type=submit] .material-symbols-outlined {
  margin-top: 2px;
}
.FlowTalk .magic .tooltip {
  color: #737373;
  font-size: 0.75rem;
  font-style: italic;
  padding: 0 1rem;
  position: relative;
  margin-left: 1rem;
}
.FlowTalk .magic .tooltip .material-symbols-outlined {
  font-size: 1rem;
  position: absolute;
  left: 0;
  bottom: 0.75px;
}
.FlowTalk .loader {
  height: 9px;
  width: 60px;
  --c: no-repeat linear-gradient(#3A02BA 0 0);
  background: var(--c), var(--c), var(--c), var(--c);
  background-size: 26% 3px;
  animation: l2 1s infinite;
}
@keyframes l2 {
  0%, 70%, 100% {
    background-position: 0% 100%, 33.3333333333% 100%, 66.6666666667% 100%, 100% 100%;
  }
  14% {
    background-position: 0% 0, 33.3333333333% 100%, 66.6666666667% 100%, 100% 100%;
  }
  28% {
    background-position: 0% 100%, 33.3333333333% 0, 66.6666666667% 100%, 100% 100%;
  }
  42% {
    background-position: 0% 100%, 33.3333333333% 100%, 66.6666666667% 0, 100% 100%;
  }
  56% {
    background-position: 0% 100%, 33.3333333333% 100%, 66.6666666667% 100%, 100% 0;
  }
}
@keyframes l5 {
  100% {
    transform: rotate(1turn);
  }
}

.form {
  width: 100vw;
  min-height: 1800px;
  margin: 50px 0;
}

.brand-portal .container {
  margin: 50px 0;
}
.brand-portal .color-containers {
  width: 100%;
}
.brand-portal .color-containers ul, .brand-portal .color-containers ul li {
  display: block;
  margin: 0.5rem;
  border-radius: 50%;
}

